import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import Burger from './burger'
import { breakXs } from '../constants'

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 2;
`

const TopBar = styled.div`
  height: 60px;
  display: flex;
  flex: 1;
  width: 100%;
  background: ${(props) => (props.menuOpen ? null : 'rgba(91, 16, 39, 0.8)')};
  align-items: center;
`

const Brand = styled(GatsbyLink)`
  font-size: 20px;
  color: #9ab29a;
  padding: 15px;
  flex: 1;
  &:hover {
    color: #9ab29a;
  }
`

const SmallHeader = ({ width, menuOpen, onClick, active, lang, homeTitle }) => {
  return (
    <Wrapper>
      <TopBar menuOpen={menuOpen}>
        <Brand to={`/${lang}/home`}>
          DIMITRI HOWALD {width >= breakXs ? homeTitle.toUpperCase() : ''}
        </Brand>
        <Burger active={active} onClick={onClick} />
      </TopBar>
    </Wrapper>
  )
}

export default SmallHeader
