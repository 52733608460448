import React from 'react'
import './burger.css'

const Burger = ({ active, onClick }) => (
  <button
    className={`hamburger hamburger--spin ${active ? 'is-active' : ''}`}
    type="button"
    onClick={onClick}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
)

export default Burger
