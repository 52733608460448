import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'
import { breakSm } from '../constants'

const Link = styled(GatsbyLink)`
  padding: ${(props) => (props.noPadding ? '0 15px' : '10px 15px')};
  font-size: 2em;
  text-transform: uppercase;
  color: #5b1027;
  @media (max-width: ${breakSm}px) {
    color: #9ab29a;
    font-size: 1.5em;
  }
`

const defaultActiveStyle = { color: '#5b1027' }

const NavLink = ({
  uri,
  lang,
  title,
  activeStyle = defaultActiveStyle,
  onClick = () => undefined,
}) => {
  return (
    <Link
      activeStyle={activeStyle}
      partiallyActive={true}
      to={`/${lang}/${uri}/`}
      onClick={onClick}
    >
      {title || uri}
    </Link>
  )
}

export default NavLink
