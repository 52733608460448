// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-bio-js": () => import("./../src/layouts/bio.js" /* webpackChunkName: "component---src-layouts-bio-js" */),
  "component---src-layouts-concerts-js": () => import("./../src/layouts/concerts.js" /* webpackChunkName: "component---src-layouts-concerts-js" */),
  "component---src-layouts-contact-js": () => import("./../src/layouts/contact.js" /* webpackChunkName: "component---src-layouts-contact-js" */),
  "component---src-layouts-home-js": () => import("./../src/layouts/home.js" /* webpackChunkName: "component---src-layouts-home-js" */),
  "component---src-layouts-press-js": () => import("./../src/layouts/press.js" /* webpackChunkName: "component---src-layouts-press-js" */),
  "component---src-layouts-project-js": () => import("./../src/layouts/project.js" /* webpackChunkName: "component---src-layouts-project-js" */),
  "component---src-layouts-projects-js": () => import("./../src/layouts/projects.js" /* webpackChunkName: "component---src-layouts-projects-js" */),
  "component---src-layouts-release-js": () => import("./../src/layouts/release.js" /* webpackChunkName: "component---src-layouts-release-js" */),
  "component---src-layouts-releases-js": () => import("./../src/layouts/releases.js" /* webpackChunkName: "component---src-layouts-releases-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

