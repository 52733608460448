import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { breakLg } from '../constants'

const LangSwitch = styled.div`
  & > a {
    font-size: 1em;
    padding: 10px 15px 0 15px;
    text-transform: lowercase;
    color: #5b1027;
  }
  display: flex;
  @media (max-width: ${breakLg}px) {
    align-self: flex-start;
  }
`

const LanguageSwitch = ({ lang }) => {
  const otherLang = lang === 'de' ? 'en' : 'de'
  const langSwitchLink = () => {
    const { pathname } = window.location
    if (pathname.includes(`/${lang}`)) {
      return pathname.replace(`/${lang}`, `/${otherLang}`)
    }
    // should be on home page
    return pathname + otherLang
  }

  return (
    <LangSwitch>
      <Link to={langSwitchLink()}>{otherLang}</Link>
    </LangSwitch>
  )
}

export default LanguageSwitch
