import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import NavLink from './navLink'
import { breakLg } from '../constants'
import LanguageSwitch from './languageSwitch'

const FlexHeader = styled.header`
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: ${breakLg}px) {
    flex-direction: column;
  }

  h1 {
    font-weight: 100;
    margin: 0;
    padding: 10px 15px;
  }

  a {
    text-decoration: none;
    transition: all 0.3s ease-out;

    &:hover {
      color: #5b1027;
    }
  }
`

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  flex: 1;
  @media (max-width: ${breakLg}px) {
    align-items: flex-start;
  }
`

const LinkWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: uppercase;

  & > a {
    font-size: 2em;
    color: #5b1027;
    padding: 15px;
    flex: 1;
  }

  @media (max-width: ${breakLg}px) {
    justify-content: flex-start;
  }
`

const Header = ({ lang, links, homeTitle }) => {
  const [width, setWidth] = useState()

  const updateDimensions = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return (
    <FlexHeader>
      <Title>
        <Link to={`/${lang}/home/`}>
          Dimitri howald {width >= breakLg && <br />}
          {homeTitle}
        </Link>
        {width < breakLg && <LanguageSwitch lang={lang} />}
      </Title>

      <NavLinks>
        {width >= breakLg && <LanguageSwitch lang={lang} />}
        <LinkWrapper>
          {links.map(({ uri, title }) => (
            <NavLink key={uri} lang={lang} uri={uri} title={title} />
          ))}
        </LinkWrapper>
      </NavLinks>
    </FlexHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  lang: 'de',
}

export default Header
