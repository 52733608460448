import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import SmallHeader from './smallHeader'
import './layout.css'
import './normalize.css'
import Background from './background'
import { breakSm } from '../constants'
import NavLink from './navLink'
import SEO from './seo'

const Root = styled.div`
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
`

const Main = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  padding: 0 15px;

  @media (max-width: ${breakSm}px) {
    margin-top: 85px;
  }
`

const Menu = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  background: rgba(91, 16, 39, 0.8);

  & a:hover {
    color: #5b1027;
  }
`

const Layout = ({ pageContext, children }) => {
  const { lang, title, description } = pageContext || {}

  // hacky way to start with big navigation
  const [width, setWidth] = useState(12000)
  const updateDimensions = () => setWidth(window.innerWidth)

  const data = useStaticQuery(graphql`
    query {
      homepage: allPagesJson(filter: { uri: { eq: "home" } }) {
        nodes {
          id
          description
          title
          parent {
            ... on File {
              absolutePath
            }
          }
        }
      }
      allContent: allPagesJson {
        nodes {
          id
          uri
          title
          parent {
            ... on File {
              absolutePath
            }
          }
        }
      }
    }
  `)

  const homepage = data.homepage.nodes.find((node) =>
    node.parent.absolutePath.includes(`/${lang}/`)
  )

  const desc = homepage ? homepage.description : description
  const homeTitle = homepage ? homepage.title : ''

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const [showMenu, setShowMenu] = useState(false)
  const links = [
    'bio',
    'projects',
    'releases',
    'concerts',
    'press',
    'contact',
  ].map((uri) => {
    const node = data.allContent.nodes.find(
      (n) => n.parent.absolutePath.includes(`/${lang}/`) && n.uri === uri
    )
    return {
      uri,
      title: node ? node.title : undefined,
    }
  })

  return (
    <>
      <Helmet>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <SEO title={title || ''} description={desc} lang={lang} />

      <Root>
        <Background />
        {width >= breakSm ? (
          <Header lang={lang} links={links} homeTitle={homeTitle} />
        ) : (
          <SmallHeader
            homeTitle={homeTitle}
            lang={lang}
            active={showMenu}
            onClick={() => setShowMenu(!showMenu)}
            menuOpen={showMenu}
            width={width}
          />
        )}

        {showMenu && (
          <Menu>
            {[{ uri: 'home' }, ...links].map((link, idx) => (
              <>
                <h2 key={idx}>
                  <NavLink
                    onClick={() => setShowMenu(false)}
                    uri={link.uri}
                    lang={lang}
                    title={link.title}
                    activeStyle={{
                      color: width >= breakSm ? '#5b1027' : '#9ab29a',
                      borderTop: 'solid 1px',
                      borderBottom: 'solid 1px',
                    }}
                  />
                </h2>
              </>
            ))}
          </Menu>
        )}

        {!showMenu && <Main>{children}</Main>}
      </Root>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  uri: PropTypes.string.isRequired,
}

export default Layout
